<template>
  <v-container fluid fill-height class='pa-0 overflow-hidden'>
    <v-row id='bpmn' class='bpmn-container' />
    <v-footer class='wd-100'>
      <span class='last-update-message wd-100'>{{ compLastLogMessage }}</span>
      <!-- eslint-disable-next-line vue/html-self-closing -->
      <br />
      <span class='last-update-date wd-100'>{{ compLastLogUpdateDate }}</span>
    </v-footer>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import BpmnViewer from 'bpmn-js';
  import { formattedDateAndTime } from '../../util/date';

  export default {
    name: 'Workflow',
    props: {
      personId: {
        type: Number,
        required: true,
      },
    },
    data: function () {
      return {
        personInfo: {},
        flowSteps: {
          COLETA: {
            stepName: 'Coleta',
            statusName: '',
          },
          RECEBIMENTO: {
            stepName: 'Finalização e recebimento de informações pelo Servidor',
            statusName: '',
          },
          ABIS: {
            stepName: 'Envio para ABIS',
            statusName: '',
          },
          CONFIRMACAO_CAPTURA_PRODAM: {
            stepName: 'Confirmação deCaptura para PRODAM',
            statusName: '[BCA] - A confirmar captura (Prodam)',
          },
          ENVIO_BIOMETRIA_PRODAM: {
            stepName: 'Envio de DadosBiométricos para PRODAM',
            statusName: '[BCA] - A enviar Dados biometricos (Prodam)',
          },
          ENVIO_DADOS_BCA: {
            stepName: 'Envio de dadospara BCA',
            statusName: '[BCA] - A enviar dados biometricos (Serpro)',
          },
          DADOS_DIVERGENTES_BCA: {
            stepName: 'Dados divergentes',
            name: 'Dados divergentes',
            type: 'error',
          },
          IM: {
            stepName: 'Intervenção Manual (IM)',
            statusName: '[BCA] - Erro ao enviar dados Serpro',
            type: 'error',
          },
          CONFIRMACAO_BCA: {
            stepName: 'Confirmação de envio de dados para BCA',
            statusName: '[BCA] - A confirmar envio de imagens',
          },
          FINALIZADO: {
            stepName: 'Finalizado',
            statusName: 'Finalizado',
          },
        },
        stepColors: {
          default: {
            stroke: '#1EA6DA',
            fill: 'rgba(30, 166, 218, 0.3)',
          },
          error: {
            stroke: '#F44336',
            fill: 'rgba(244, 67, 54, 0.3)',
          },
          success: {
            stroke: '#4CAF50',
            fill: 'rgba(76, 175, 80, 0.3)',
          },
        },
        /* eslint-disable */
        detranFlowXml: `
          <bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" id="Definitions_00fu3z3" targetNamespace="http://bpmn.io/schema/bpmn" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="9.1.0">	
            <bpmn:process id="Process_039qeyz" isExecutable="false">	
              <bpmn:task id="Activity_0ywu41v" name="Coleta">	
                <bpmn:outgoing>Flow_1l35jrs</bpmn:outgoing>	
              </bpmn:task>	
              <bpmn:task id="Activity_14qpla7" name="Finalização e recebimento de informações pelo Servidor">	
                <bpmn:incoming>Flow_1l35jrs</bpmn:incoming>	
                <bpmn:outgoing>Flow_0nooser</bpmn:outgoing>	
                <bpmn:outgoing>Flow_0g7h4rc</bpmn:outgoing>	
              </bpmn:task>	
              <bpmn:sequenceFlow id="Flow_1l35jrs" sourceRef="Activity_0ywu41v" targetRef="Activity_14qpla7" />	
              <bpmn:task id="Activity_18dzncw" name="Confirmação de Captura para PRODAM">	
                <bpmn:incoming>Flow_0nooser</bpmn:incoming>	
                <bpmn:outgoing>Flow_12g3am0</bpmn:outgoing>	
              </bpmn:task>	
              <bpmn:sequenceFlow id="Flow_0nooser" sourceRef="Activity_14qpla7" targetRef="Activity_18dzncw" />	
              <bpmn:task id="Activity_0vlycvz" name="Envio de Dados Biométricos para PRODAM">	
                <bpmn:incoming>Flow_12g3am0</bpmn:incoming>	
                <bpmn:outgoing>Flow_151evki</bpmn:outgoing>	
              </bpmn:task>	
              <bpmn:sequenceFlow id="Flow_12g3am0" sourceRef="Activity_18dzncw" targetRef="Activity_0vlycvz" />	
              <bpmn:endEvent id="Event_0mqlse2" name="Fim">	
                <bpmn:incoming>Flow_0sii3y7</bpmn:incoming>	
              </bpmn:endEvent>	
              <bpmn:task id="Activity_0y2rgh5" name="Envio para ABIS">	
                <bpmn:incoming>Flow_0g7h4rc</bpmn:incoming>	
              </bpmn:task>	
              <bpmn:sequenceFlow id="Flow_0g7h4rc" sourceRef="Activity_14qpla7" targetRef="Activity_0y2rgh5" />	
              <bpmn:task id="Activity_1arizn4" name="Envio de dados para BCA">	
                <bpmn:incoming>Flow_151evki</bpmn:incoming>	
                <bpmn:outgoing>Flow_0f1ixnf</bpmn:outgoing>	
                <bpmn:outgoing>Flow_0vc4nk1</bpmn:outgoing>	
                <bpmn:outgoing>Flow_1wvcjw6</bpmn:outgoing>	
              </bpmn:task>	
              <bpmn:sequenceFlow id="Flow_151evki" sourceRef="Activity_0vlycvz" targetRef="Activity_1arizn4" />	
              <bpmn:task id="Activity_02bpy2m" name="Confirmação de envio de dados para BCA">	
                <bpmn:incoming>Flow_0f1ixnf</bpmn:incoming>	
                <bpmn:outgoing>Flow_0sii3y7</bpmn:outgoing>	
              </bpmn:task>	
              <bpmn:sequenceFlow id="Flow_0f1ixnf" sourceRef="Activity_1arizn4" targetRef="Activity_02bpy2m" />	
              <bpmn:sequenceFlow id="Flow_0sii3y7" sourceRef="Activity_02bpy2m" targetRef="Event_0mqlse2" />	
              <bpmn:task id="Activity_1fdawgs" name="Intervenção Manual (IM)">	
                <bpmn:incoming>Flow_0vc4nk1</bpmn:incoming>	
              </bpmn:task>	
              <bpmn:sequenceFlow id="Flow_0vc4nk1" sourceRef="Activity_1arizn4" targetRef="Activity_1fdawgs" />	
              <bpmn:task id="Activity_1n3xk0f" name="Dados divergentes">	
                <bpmn:incoming>Flow_1wvcjw6</bpmn:incoming>	
              </bpmn:task>	
              <bpmn:sequenceFlow id="Flow_1wvcjw6" sourceRef="Activity_1arizn4" targetRef="Activity_1n3xk0f" />	
            </bpmn:process>	
            <bpmndi:BPMNDiagram id="BPMNDiagram_1">	
              <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_039qeyz">	
                <bpmndi:BPMNEdge id="Flow_0vc4nk1_di" bpmnElement="Flow_0vc4nk1">	
                  <di:waypoint x="850" y="289" />	
                  <di:waypoint x="850" y="340" />	
                </bpmndi:BPMNEdge>	
                <bpmndi:BPMNEdge id="Flow_0sii3y7_di" bpmnElement="Flow_0sii3y7">	
                  <di:waypoint x="1060" y="249" />	
                  <di:waypoint x="1162" y="249" />	
                </bpmndi:BPMNEdge>	
                <bpmndi:BPMNEdge id="Flow_0f1ixnf_di" bpmnElement="Flow_0f1ixnf">	
                  <di:waypoint x="900" y="249" />	
                  <di:waypoint x="960" y="249" />	
                </bpmndi:BPMNEdge>	
                <bpmndi:BPMNEdge id="Flow_151evki_di" bpmnElement="Flow_151evki">	
                  <di:waypoint x="740" y="249" />	
                  <di:waypoint x="800" y="249" />	
                </bpmndi:BPMNEdge>	
                <bpmndi:BPMNEdge id="Flow_0g7h4rc_di" bpmnElement="Flow_0g7h4rc">	
                  <di:waypoint x="370" y="289" />	
                  <di:waypoint x="370" y="340" />	
                </bpmndi:BPMNEdge>	
                <bpmndi:BPMNEdge id="Flow_12g3am0_di" bpmnElement="Flow_12g3am0">	
                  <di:waypoint x="580" y="249" />	
                  <di:waypoint x="640" y="249" />	
                </bpmndi:BPMNEdge>	
                <bpmndi:BPMNEdge id="Flow_0nooser_di" bpmnElement="Flow_0nooser">	
                  <di:waypoint x="420" y="249" />	
                  <di:waypoint x="480" y="249" />	
                </bpmndi:BPMNEdge>	
                <bpmndi:BPMNEdge id="Flow_1l35jrs_di" bpmnElement="Flow_1l35jrs">	
                  <di:waypoint x="260" y="249" />	
                  <di:waypoint x="320" y="249" />	
                </bpmndi:BPMNEdge>	
                <bpmndi:BPMNEdge id="Flow_1wvcjw6_di" bpmnElement="Flow_1wvcjw6">	
                  <di:waypoint x="850" y="209" />	
                  <di:waypoint x="850" y="160" />	
                </bpmndi:BPMNEdge>	
                <bpmndi:BPMNShape id="Activity_0ywu41v_di" bpmnElement="Activity_0ywu41v">	
                  <dc:Bounds x="160" y="209" width="100" height="80" />	
                  <bpmndi:BPMNLabel />	
                </bpmndi:BPMNShape>	
                <bpmndi:BPMNShape id="Activity_14qpla7_di" bpmnElement="Activity_14qpla7">	
                  <dc:Bounds x="320" y="209" width="100" height="80" />	
                  <bpmndi:BPMNLabel />	
                </bpmndi:BPMNShape>	
                <bpmndi:BPMNShape id="Activity_18dzncw_di" bpmnElement="Activity_18dzncw">	
                  <dc:Bounds x="480" y="209" width="100" height="80" />	
                  <bpmndi:BPMNLabel />	
                </bpmndi:BPMNShape>	
                <bpmndi:BPMNShape id="Activity_0vlycvz_di" bpmnElement="Activity_0vlycvz">	
                  <dc:Bounds x="640" y="209" width="100" height="80" />	
                  <bpmndi:BPMNLabel />	
                </bpmndi:BPMNShape>	
                <bpmndi:BPMNShape id="Activity_0y2rgh5_di" bpmnElement="Activity_0y2rgh5">	
                  <dc:Bounds x="320" y="340" width="100" height="80" />	
                  <bpmndi:BPMNLabel />	
                </bpmndi:BPMNShape>	
                <bpmndi:BPMNShape id="Activity_1arizn4_di" bpmnElement="Activity_1arizn4">	
                  <dc:Bounds x="800" y="209" width="100" height="80" />	
                  <bpmndi:BPMNLabel />	
                </bpmndi:BPMNShape>	
                <bpmndi:BPMNShape id="Activity_02bpy2m_di" bpmnElement="Activity_02bpy2m">	
                  <dc:Bounds x="960" y="209" width="100" height="80" />	
                  <bpmndi:BPMNLabel />	
                </bpmndi:BPMNShape>	
                <bpmndi:BPMNShape id="Activity_1fdawgs_di" bpmnElement="Activity_1fdawgs">	
                  <dc:Bounds x="800" y="340" width="100" height="80" />	
                  <bpmndi:BPMNLabel />	
                </bpmndi:BPMNShape>	
                <bpmndi:BPMNShape id="Event_0mqlse2_di" bpmnElement="Event_0mqlse2">	
                  <dc:Bounds x="1162" y="231" width="36" height="36" />	
                  <bpmndi:BPMNLabel>	
                    <dc:Bounds x="1171" y="274" width="19" height="14" />	
                  </bpmndi:BPMNLabel>	
                </bpmndi:BPMNShape>	
                <bpmndi:BPMNShape id="Activity_1n3xk0f_di" bpmnElement="Activity_1n3xk0f">	
                  <dc:Bounds x="800" y="80" width="100" height="80" />	
                  <bpmndi:BPMNLabel />	
                </bpmndi:BPMNShape>	
              </bpmndi:BPMNPlane>	
            </bpmndi:BPMNDiagram>	
          </bpmn:definitions>
        `,
        /* eslint-enable */
      };
    },
    computed: {
      compLastLogMessage: function () {
        if (this.personInfo) {
          const { code, message } = this.personInfo;
          return `${code} ${code ? '-' : ''} ${message}`;
        }
        return this.$t('NO_INTEGRATION_INFO');
      },
      compLastLogUpdateDate: function () {
        if (this.personInfo && this.personInfo.last_log_date) {
          return `${this.$t('LAST_UPDATE_ON')} ${this.formattedDateAndTime(this.personInfo.last_log_date)}`;
        }
        return `${this.$t('LAST_UPDATE_ON')} ${this.$t('TIMEDATE_MASK')}`;
      },
    },
    mounted: async function () {
      this.getPersonStatus();
    },
    methods: {
      formattedDateAndTime: function (value) {
        return formattedDateAndTime(value);
      },
      getPersonStatus: async function () {
        const { data } = await axios({
          url: `/person/integration-status/${this.personId}`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.personInfo = data;
        this.loadBpmnDiagram();
      },
      loadBpmnDiagram: function () {
        const viewer = new BpmnViewer({
          container: '#bpmn',
        });

        viewer.importXML(this.detranFlowXml).then(() => {
          const bpmnElement = document.getElementById('bpmn');
          const steps = bpmnElement.getElementsByClassName('djs-element djs-shape');
          let step;

          if (this.personInfo && this.personInfo.code === 'DADOS_DIVERGENTES_BCA') {
            step = this.flowSteps.DADOS_DIVERGENTES_BCA;
          } else {
            step = this.getStepByEnrollmentStatus((this.personInfo && this.personInfo.status) || undefined);
          }

          for (let i = 0; i < steps.length; i++) {
            if (step.name) {
              if (steps[i].textContent === '' && this.personInfo.status === this.flowSteps.FINALIZADO.statusName) {
                steps[i].getElementsByTagName('circle')[0].style.stroke = this.stepColors.success.stroke;
                steps[i].getElementsByTagName('circle')[0].style.fill = this.stepColors.success.fill;
              } else if (steps[i].textContent === step.name) {
                steps[i].getElementsByTagName('rect')[0].style.stroke = step.type === 'error' ? this.stepColors.error.stroke : this.stepColors.default.stroke;
                steps[i].getElementsByTagName('rect')[0].style.fill = step.type === 'error' ? this.stepColors.error.fill : this.stepColors.default.fill;
                steps[i].getElementsByTagName('rect')[0].style.boxShadow = '20px 20px 10px blue';
              }
            }
          }

          viewer.get('canvas').zoom('fit-viewport');
          this.$nextTick(() => {
            document.getElementsByClassName('bjs-powered-by')[0].style.display = 'none';
          });
        }).catch((err) => {
          const { warnings, message } = err;

          console.log('something went wrong:', warnings, message); /* eslint-disable-line no-console */
        });
      },
      getStepByEnrollmentStatus: function (enrollmentStatus) {
        const step = { name: '', type: '' };
        if (enrollmentStatus) {
          const types = Object.keys(this.flowSteps);
          types.forEach((type) => {
            if (this.flowSteps[type].statusName === enrollmentStatus) {
              step.name = this.flowSteps[type].stepName;
              step.type = this.flowSteps[type].type;
            }
          });
        }
        return step;
      },
    },
  };
</script>

<style>
  .bpmn-container {
    height: 26vh;
    margin-bottom: 4vh;
    margin-top: 4vh;
    margin-left: 4vw;
  }
  .last-update-message {
    font-size: 0.93em;
  }
  .last-update-date {
    font-size: 0.93em;
    color: #AAAAAA;
    font-style: italic
  }
</style>
